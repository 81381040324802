import React, { Component } from 'react';
import Menu from '../comonents/Menu';
import Tool from '../comonents/Tool';
import './App.scss';
import Footer from '../comonents/Footer';
import Contact from '../comonents/Contact';
import "typeface-raleway";
import Router from '../comonents/Router';
var Highlight = require('react-highlighter');
class App extends Component {
    state = { text: '' };
    searchValue = '';
    
    
    constructor(props){
        super(props);
    }
    
    onSearchSubmit(value) {
        console.log("value", typeof value);
        this.setState({text: value});
    }


    


    render() {
        


        return (
            <>

                <Highlight search={this.state.text}>
                <Menu></Menu>
                <Tool onSubmit={this.onSearchSubmit}></Tool>
                <div className='container'>
                   <Router></Router>
                </div>
                <Contact email={'dvmoomoodv@gmail.com'} message={'같이 재미있는 프로그램 만들어봐요'}></Contact>
                <Footer name={'dvmoomoodv'}></Footer>
                </Highlight>
            </>
        );
    }
}

export default App;