import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
const menulist = require('../json/menu.json')
const hideMenu = () => {
    window.scrollTo(0, 0)
    // redux 활용 해볼까?
    const el = document.querySelector('#navbarNav');
    if (el.classList.contains("show")) {
        el.classList.remove("show");
    }
}
export default class Menu extends Component{
    render() {
        return (
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                <span className="navbar-brand">
                    <Logo></Logo>
                </span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        {
                            menulist.length > 0 &&
                            menulist.map((menu, i) => {
                                return (
                                    
                                    <li className="nav-item active" key={i}>
                                        <NavLink className="nav-link" exact to={menu.to} onClick={hideMenu} activeClassName={'text-pink'}>{menu.title}</NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                </div>
            </nav>
        )
    }
}