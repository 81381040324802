import React, {Component} from 'react';
import PaginationList from 'react-pagination-list';
import axios from "axios";
// import { useTranslation } from 'react-i18next'
/**
 * firebase 적용하기
 * https://www.youtube.com/watch?v=TfDe1dG0tHc&list=PLRB_0OYQAHMPu7EsP51ksMd489CrEfICT&index=9
 */

export default class Posts extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            count: 0,
            list: {}
        };

        this.loadItem = async() => {
            axios
                .get("https://blog.dvmoomoodv.com/wp-json/wp/v2/tags")
                .then(({data}) => {
                    console.log(data);
                    this.setState({loading: true, count: data.length, list: data});
                })
                .catch(e => { // API 호출이 실패한 경우
                    console.error(e); // 에러표시
                    this.setState({loading: false});
                });
        };
    }

    componentDidMount() {
        this.loadItem(); // loadItem 호출
    }

    render() {
        let listRender;
        console.log(this.state);
        if (this.state.count > 0) {
            listRender = <PaginationList
                data={this.state.list}
                pageSize={3}
                renderItem={(item, key) => (
                <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="blog items"
                    className="mb-3"
                    key={key}>
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-2">
                                {Object
                                    .keys(item)
                                    .map((item, j) => {
                                        return <span className="badge badge-light" key={j}>{item.count}</span>
                                    })}
                            </div>
                            <h5 className="card-title text-truncate">
                                <i className="fab fa-wordpress-simple mr-2"></i>
                                {item.name}
                            </h5>
                        </div>
                    </div>
                </a>
            )}></PaginationList>
        } else {
            listRender = <div className="row">
                <div className="col text-center">
                </div>
            </div>
        }

        return (
            <div className="posts">
                <div className="row">
                    <div className="col-12 title">
                        <span className="d-block">작성한 블로그 태그들입니다. </span>
                        <span className="d-block text-secondary h6">RSS리더에서 API로 구성변경후 페이지 수정중입니다.</span>
                    </div>
                </div>
                {listRender}
            </div>
        )
    }
}