import React, { Component } from 'react';

export default class Footer extends Component{
    render(props) {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col-6 text-center">
                        Copyright {this.props.name} {new Date().getFullYear()}
                        </div>
                        <div className="col-3 text-right h6">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}