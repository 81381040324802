import React, { Component } from 'react';

export default class Logo extends Component{
    constructor(props){
        super(props);
        this.state = {
            name : 'MH'
        }
    }
    
    render() {
        return (
            <div className="logo">
               <div>
                    <span>{this.state.name}</span>
               </div>
            </div>
        )
    }
}