import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home, About, Posts, Careers, Study, Log} from '../pages';
class Router extends Component {
    render() {
        return (
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/careers" component={Careers}/>
                    <Route exact path="/study" component={Study}/>
                    <Route exact path="/posts" component={Posts}/>
                    <Route exact path="/log" component={Log}/>
                </Switch>
        );
    }
}

export default Router;