import React, { Component } from 'react';
import Logo from '../comonents/Logo';
import profoileImg from '../image/profile.jpg'
import GitHubCalendar from 'react-github-calendar';

export default class Posts extends Component{
    constructor(props){
        super(props);
    }
    render() {
        return (
        <div className="about">
            <Logo></Logo>
            <div className="row">
                {/* <div className="col-12 title">
                    <span className="d-block">소개</span>
                </div> */}
                <div className="col-12">
                    <div className="bg shadow"></div>
                    <img className="shadow" src={ profoileImg } alt="img"/>    
                </div>
                
                <div className="col-12 mt-3">
                    <span className="d-block font-weight-bold">이무현</span>
                    <span className="d-block">Moohyun Lee</span>
                    <span className="d-block">dvmoomoodv@gmail.com</span>
                </div>

                <div className="col-12 mt-3">
                    <a href="https://www.facebook.com/dvmoomoodv/" target="_blank" rel="noopener noreferrer" alt="github"> <i className="fab fa-facebook-square h2"></i></a>
                    <a href="https://twitter.com/dvmoomoodv" target="_blank" rel="noopener noreferrer" alt="github"><i className="fab fa-twitter-square h2 ml-1"></i></a>
                    <a href="https://github.com/dvmoomoodv" target="_blank" rel="noopener noreferrer" alt="github"><i className="fab fa-github-square h2 ml-1"></i></a>
                    <a href="https://www.linkedin.com/in/moomoo-lee-65655712b/" target="_blank" rel="noopener noreferrer" alt="github"><i className="fab fa-linkedin h2 ml-1"></i></a>
                </div>
                <div className="col-12">
                   
                    <a href="./resume.docx" target="_blank" rel="noopener noreferrer" alt="github" className="btn btn-sm btn-primary">
                        <i className="fas fa-file"></i> 이력서 워드
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-12 title sub">
                    학교
                </div>
                <div className="col-12">
                    <span className="d-block date">
                    2008.03 
~ 2015.02
                    </span>
                    <span className="d-block">
                    성공회대학교 소프트웨어공학<br></br>
                    </span>           
                </div>
            </div>
          

            <div className="row">
                <div className="col-12 title sub">
                    군대
                </div>
                <div className="col-12">
                    <span className="d-block date">
                2010.02~ 2011.11
                    </span>
                    <span className="d-block">
소프트웨어개발병 
<br></br>
(1713.B7 - 현:175262)      
                    </span> 
               	
                </div>
            </div>

            <div className="row">
                <div className="col-12 title sub">
                    {new Date().getFullYear() + '년 깃허브 그래프'}
                </div>
                <div className="col-12">
                    <GitHubCalendar username="dvmoomoodv" color="hsl(338, 88%, 56%)" years={[new Date().getFullYear()]} showTotalCount={false}/>
                </div>
            </div>
                    
        </div>
        )
    }
}