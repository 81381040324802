import React, { Component } from 'react';
export default class Log extends Component{
    render() {
        return (
            <div className="log">
                <h2>수정 기록</h2> 
                <ul>
                    <li>
                        <h5>0.01</h5>
                        <ul>
                            <li>
                                기본 디자인, 기존 이력서 및 컨텐츠로 화면 구성
                            </li>
                            <li>
                                React 컴포넌트 설계
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>0.02</h5>
                        <ul>
                            <li>
                                기술명 검색기능
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2>
                    버전 1.00 목표
                </h2>
                <ul>
                    <li>
                        블로그 및 외부 API 연동 
                    </li>
                    <li>
                        애니메이션 등 디자인 감각을 뽐낼수 있는 페이지
                    </li>
                    <li>
                        이력서 pdf 다운로드 기능
                    </li>
                </ul>
            </div>
        )
    }
}