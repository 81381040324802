import React, { Component } from 'react';
import career1Img from '../image/career1.jpg'
import career2Img from '../image/career2.jpg'
import career3Img from '../image/career3.jpg'
import { Doughnut } from 'react-chartjs-2';
import Markdown from 'react-markdown'
const careerlist = require('../careerlist.json')
/**
 * 경력 기간별 그래프 
 */
const data1 = {
	labels: [
		'프로젝트관리자',
		'개발자',
		'디자이너'
	],
	datasets: [{
		data: [2, 20, 3],
		backgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a'],
		hoverBackgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a']
	}]
};
const data2 = {
	labels: [
		'정규',
		'프리랜서',
	],
	datasets: [{
		data: [3, 16],
		backgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a'],
		hoverBackgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a']
	}]
};
const data3 = {
	labels: [
		'PHP',
		'Java',
		'Node.js'
	],
	datasets: [{
		data: [8, 7, 1],
		backgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a'],
		hoverBackgroundColor: ['#fd1e6f', '#f989a2', '#e5ced9', '#f4777f', '#93003a']
	}]
};

export default class Posts extends Component{
	constructor(props){
		super(props);
		this.state = {
            information: [],
  			keyword: '',
        }
	}
	handleChange = (e) => {
		this.setState({
			keyword: e.target.value,
		});
	}
    render() {
		const filteredList = careerlist.filter(
			post => post.tech.toLowerCase().indexOf(this.state.keyword.toLowerCase()) !== -1
		)

		let listRender;
		if(filteredList.length > 0){
			listRender = filteredList.length > 0 &&
			filteredList.map((post, i) => {
				return (
				<div className="row" key={i}>
					
					<div className="col-12 title sub">
						<div className="row">
							<div className="col-12">
								
							</div>
						</div>
						<span className="badge badge-pink">{post.role}</span>
						{post.title}
						{post.site ? <a href={post.site} target="_blank" rel="noopener noreferrer" className="ml-1 text-secondary"><i className="fas fa-home"></i> 홈페이지 </a> : ''}
						<span className="date">{post.period}</span>
					</div>
					<div className="col-12">
						{
							post.tech.split(',').map((item, j) => {
								return <span className={item.toLowerCase() === this.state.keyword.toLowerCase() ? 'tag sm active' : 'tag sm'} key={j}>{item}</span>
							})
						}
					</div>
					<div className="col-12 py-3">
						<Markdown source={post.content} escapeHtml={false}></Markdown>
					</div>
				</div>
				)
			})
		} else {
			listRender = <div className="row">
				<div className="col text-center">
					내용이 없습니다
				</div>
			</div>
		}

        return (
        <div className="career">
			<div className="row">
				<div className="col text-center mb-5">
					기술명 입력: <input placeholder="예: java" onChange={this.handleChange} value={this.state.keyword}/>
				</div>
			</div>
			<img className="bg" src={ career1Img } alt="img"/>
			<img className="bg" src={ career2Img } alt="img"/>
			<img className="bg" src={ career3Img } alt="img"/>
			<a className="btn btn-sm btn-outline-pink" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">요약</a>
			<div className="collapse" id="collapseExample">
				<div className="card card-body">
					<div className="row text-center pb-2 summary">
						<div className="col-md-6">
							<h6 className="py-3">회사수 </h6>
							<h4 className="count data">16</h4>
						</div>
						<div className="col-md-6">
							<h6 className="py-3">프로젝트수</h6>
							<h4 className="count data">29</h4>
						</div>
						<div className="col-md-6">
							<h6 className="py-3">기술수</h6>
							<h4 className="count data">39</h4>
						</div>
						<div className="col-md-6">
							<h6 className="py-3">역할</h6>
							<Doughnut className="data" data={data1}></Doughnut>
						</div>
						<div className="col-md-6">
							<h6 className="py-3">업체</h6>
							<Doughnut className="data" data={data2}></Doughnut>
						</div>
						<div className="col-md-6">
							<h6 className="py-3">주언어</h6>
							<Doughnut className="data" data={data3}></Doughnut>
						</div>
					</div>
				</div>
			</div>
			
			{listRender}
        </div>
		)
    }
}