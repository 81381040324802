import React, { Component } from 'react';

// tools 상태 관리는 리덕스를 공부해서 적용해보자.
// let toggle = false;

export default class Tool extends Component {
    state = { text: '' };
    onFormSubmit = e => {
        e.preventDefault();
        this.props.onSubmit(this.state.text);
        return false;
    }
    toggleBox = () => {
        const el = document.querySelector('.list');
        if (el.classList.contains("show")) {
            el.classList.remove("show");
        }else{
            el.classList.add("show");
        }
    }
    render() {
        return (
            <div className="tool row">
                <div className="h6 col-12 text-right"  onClick={this.toggleBox}>
                        <i className="fas fa-tools text-pink"></i>
                </div>
                <div className="list col-12">
                        <ul>
                            <li>
                                Tools
                                <form onSubmit={this.onFormSubmit}>
                                <input 
                                    value={this.state.text}
                                    onChange={(e) => {this.setState({ text: e.target.value})}}/>
                                </form>
                            </li>
                        </ul>
                </div>
            </div>
        );
    }
}