import React, { Component } from 'react';
import bgImg from '../image/bg.jpg'
import Markdown from 'react-markdown'
const home1 = require('../json/home1.json')
const home2 = require('../json/home2.json')

export default class Posts extends Component{
    render() {
        return (
        <div className="home">
            <div className="row">
                <div className="col-12 title">
                    <span className="d-block">안녕하세요. 이무현입니다. </span>
                    <span className="d-block">저는 프론트지향 풀스택 개발자입니다.  </span>
                    
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={ bgImg } alt="img"/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 title sub">
                     프론트지향 풀스택 개발자라고 하는 이유?
                </div>
                <div className="col-12">
                    <Markdown source={home1.content} escapeHtml={false}></Markdown>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-12 title sub">
                     개발이란?
                </div>
                <div className="col-12">
                    <Markdown source={home2.content} escapeHtml={false}></Markdown>
                </div>
            </div>
            <div className="row">
                <div className="col-12 title sub">
                     개발자입니다.
                </div>
                <div className="col-12 title item">
                   Domain
                </div>
                <div className="col-12 px-4 py-3">
                    <p>
                        <span className="tag">전사적자원관리(ERP)</span>  
                        <span className="tag">교육</span>  
                        <span className="tag">사무자동화(OA)</span>
                        <span className="tag">보안</span>
                    </p>
                </div>
                <div className="col-12 title item">
                    Web Fullstack Developer
                </div>
                <div className="col-12 px-4 py-3">
                    <p>
                        <span className="tag">Front end</span>
                        <span className="mr-3 text-secondary">JQuery</span>
                        <span className="mr-3 text-secondary">Angular</span>
                        <span className="mr-3 text-secondary">React</span>
                        </p>

                    <p>
                        <span className="tag">Back end</span>  
                        <span className="mr-3 text-secondary">PHP</span>
                        <span className="mr-3 text-secondary">Java</span>
                        <span className="mr-3 text-secondary">Node.js</span>
                    </p>
                    <p>
                        <span className="tag">Database</span>
                        <span className="mr-3 text-secondary">MySQL</span>
                        <span className="mr-3 text-secondary">OracleDB</span>
                        <span className="mr-3 text-secondary">MSSQL</span>
                    </p>
                    <p>
                        <span className="tag pr-5">Etc</span>
                        <span className="mr-3 text-secondary">Git</span>
                        <span className="mr-3 text-secondary">SVN</span>
                        <span className="mr-3 text-secondary">CVS</span>
                        <span className="mr-3 text-secondary">Linux</span>
                    </p>
                </div>
                <div className="col-12 title item">
                   Web Publisher
                </div>
                <div className="col-12 px-4 py-3">
                    <p>
                        <span className="tag">Sass</span>  
                        <span className="tag">Gulp</span>  
                        <span className="tag">Bootstrap</span>
                    </p>
                </div>
                <div className="col-12 title item">
                    UI/UX Designer
                </div>
                <div className="col-12 px-4 py-3">
                    <p>
                        <span className="tag">Adobe Photoshop</span> 
                    </p>
                </div>
            </div>
        </div>
        )
    }
}