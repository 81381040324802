import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
const menulist = require('../json/menu.json')

const scrollTop = () => {
    window.scrollTo(0, 0)
}
export default class Contact extends Component{
    render() {
        const {email, message} = this.props
        return (
            <div className="contact">
                  <div className="col-12 border-bottom pb-3">
                    <div className="btn-group" role="group">
                  {
                            menulist.length > 0 &&
                            menulist.map((menu, i) => {
                                return (

                                        <NavLink className="btn btn-outline-pink" exact to={menu.to} onClick={scrollTop} key={i}>{menu.title}</NavLink>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4>{message}</h4>
                             <a href={'mailto::' + email} className="btn btn-outline-pink" target="_blank" rel="noopener noreferrer">dvmoomoodv@gmail.com</a>
                        </div>
                        
                    </div>
                </div>
              
            </div>
        )
    }
}